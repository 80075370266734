<template>
  <div class="mian">
    <div class="main-top" v-if="type">
      <img
        class="top-bar"
        src="https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/top-bar.png"
      />
      <div class="main-top-title">答问卷点亮拼图 赢取奖励</div>
    </div>

    <div class="main-bottom" v-if="type">
      <div class="main-content-fa">
        <div class="main-content">
          <div class="content-left">
            <div
              class="img-box"
              :style="{
                backgroundImage: 'url(' + imgSrc + ')'
              }"
            >
              <img
                :src="
                  coordinateList.includes(item.coordinate)
                    ? item.newUrl
                    : item.url
                "
                :class="`img-abs img-abs${item.id}`"
                v-for="item in list"
                :key="item.id"
              />
            </div>
          </div>
          <div class="content-right">
            <div
              class="right-top-title"
              :style="{
                backgroundImage: 'url(' + imgSrc2 + ')'
              }"
            >
              点亮碎片领奖励
            </div>
            <div class="text text1"><span></span>游戏玩法</div>
            <div class="text text2">
              参与新版速调吧答卷完成的同时可以随机获得一片碎片，集齐每一关卡的碎片，可以领取每一关卡的奖励。奖励包含，抽奖卡、翻倍卡、免审卡、复活卡等等
            </div>
            <div class="text text3"><span></span>游戏规则</div>
            <div class="text text2">
              只针对商业调查才会获得集卡碎片，小幸运调查，K调查，G调查都不会获得。
              每完成一个商业调查都会随机获得，本次总共推出6个关卡，每个关卡的难度也会逐渐增加，获得奖励也会更加丰富。
            </div>
            <div class="progress-box">
              <div class="progress-left">
                <img class="icon" src="@/assets/images/xbzq/right-icon1.png" />
                <div class="progress-box-chind">
                  <el-progress
                    :percentage="percentage1"
                    :stroke-width="15"
                    color="#FF8A01"
                    :text-inside="true"
                    :format="format1"
                  ></el-progress>
                </div>
              </div>
              <div class="progress-right">
                <img class="icon" src="@/assets/images/xbzq/right-icon2.png" />
                <div class="progress-box-chind">
                  <el-progress
                    :percentage="percentage2"
                    :stroke-width="15"
                    color="#FF8A01"
                    :text-inside="true"
                    :format="format2"
                  ></el-progress>
                </div>
              </div>
            </div>
            <div class="btns">
              <div
                class="btns-box"
                :style="{
                  backgroundImage: 'url(' + imgSrc3 + ')'
                }"
                @click="gourl"
              >
                去点亮
              </div>
              <div
                :class="[
                  'btns-box',
                  { 'btns-box2': percentage1 !== 100 || islingqu }
                ]"
                :style="{
                  backgroundImage: 'url(' + imgSrc3 + ')'
                }"
                @click="getRewards"
              >
                {{ islingqu ? "已领取" : "领取奖励" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fragment-box" v-if="type">
      <img class="fragment-top" src="@/assets/images/fragment-top.png" />
      <img
        class="fragment-icon fragment-icon-left"
        src="@/assets/images/fragment-icon.png"
      />
      <img
        class="fragment-icon fragment-icon-right"
        src="@/assets/images/fragment-icon.png"
      />
      <div class="lubo-box-fa">
        <div class="lubo-box">
          <div class="lubo" id="lubo">
            <div class="lubo-item" v-for="item in list" :key="item.id">
              <div class="lubo-item-top-box">
                <div class="lubo-item-fragment">
                  <img
                    class="fragment2-img"
                    :src="
                      `https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment2/${
                        coordinateList.includes(item.coordinate)
                          ? item.fragment
                          : item.fragment + '-0'
                      }.png`
                    "
                  />
                  <img
                    v-if="coordinateList.includes(item.coordinate)"
                    class="triangle-dui"
                    src="@/assets/images/triangle-dui.png"
                  />
                </div>
              </div>
              <div
                :class="[
                  'lubo-item-bottom-box',
                  {
                    'lubo-item-bottom-box-active': coordinateList.includes(
                      item.coordinate
                    )
                  }
                ]"
              >
                {{
                  coordinateList.includes(item.coordinate) ? "已点亮" : "待点亮"
                }}
              </div>
            </div>
          </div>
          <!-- 上一个 -->
          <div class="prev-box lubo-btn" @click="prev">
            <img src="@/assets/images/triangle-left.png" />
          </div>
          <!-- 下一个 -->
          <div class="next-box lubo-btn" @click="next">
            <img src="@/assets/images/triangle-right.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- 点亮记录和我的奖品 -->
    <div class="Light-tabar">
      <div
        :class="['Light-box', { 'Light-box-active': lightType === 1 }]"
        @click="changeLightType(1)"
      >
        点亮记录
      </div>
      <div
        :class="['Light-box', { 'Light-box-active': lightType === 2 }]"
        @click="changeLightType(2)"
      >
        我的奖品
      </div>
    </div>
    <div class="Light-award" v-if="lightType == 1">
      <div class="Light-award-list" @scroll="scroll">
        <div
          class="Light-award-item"
          v-for="(item, index) in jigsawPieceRecordsList"
          :key="index"
        >
          <div class="Light-award-left">{{ item.CreateDate | fromDate }}</div>
          <div class="list-content">
            {{ item.ProgressName }}
          </div>
          <div class="list-right">
            {{ item.PieceName }}
          </div>
        </div>
        <div
          class="Light-award-box-tips"
          v-if="jigsawPieceRecordsList.length == 0"
        >
          暂无抽奖记录
        </div>
      </div>
    </div>
    <div class="Light-award" v-if="lightType == 2">
      <div class="Light-award-list">
        <div
          class="Light-award-item"
          v-for="(item, index) in newLevelList"
          :key="index"
        >
          <div class="Light-award-left">{{ item.Name }}</div>
          <div class="list-right list-AwardStatus">
            {{ item.AwardStatus === 2 ? "已领取" : "未领取" }}
          </div>
        </div>
        <div class="Light-award-box-tips" v-if="newLevelList.length == 0">
          暂无奖品记录
        </div>
      </div>
    </div>
    <div class="zhezhao" v-if="fragmentShow"></div>
    <div class="jl-box" v-if="fragmentShow">
      <div class="jlt-box">
        <img class="jltc" src="@/assets/images/jiangli.png" />
        <div class="jltc-name">
          {{
            this.type === this.allLevelList.length
              ? "恭喜你完成全部关卡"
              : "恭喜解锁下一关"
          }}
        </div>
        <div
          :class="[
            'jltc-card',
            {
              'jltc-card2': isIoconNumber === 2
            },
            {
              'jltc-card3': isIoconNumber === 3
            },
            {
              'jltc-card4': isIoconNumber === 4
            },
            {
              'jltc-card5': isIoconNumber === 5
            }
          ]"
        >
          <div class="card-item" v-if="Points">
            <img class="card-icon card-icon1" src="@/assets/images/icon1.png" />
            <div class="card-item-name">金币X{{ Points }}</div>
          </div>
          <div class="card-item" v-if="LotteryCard">
            <img class="card-icon" src="@/assets/images/icon2.png" />
            <div class="card-item-name">抽奖卡X{{ LotteryCard }}</div>
          </div>
          <div class="card-item" v-if="DoubleCard">
            <img class="card-icon" src="@/assets/images/icon3.png" />
            <div class="card-item-name">免审卡X{{ DoubleCard }}</div>
          </div>
          <div class="card-item" v-if="UncheckCard">
            <img class="card-icon" src="@/assets/images/icon4.png" />
            <div class="card-item-name">翻倍卡X{{ UncheckCard }}</div>
          </div>
          <div class="card-item" v-if="ResurgentCard">
            <img class="card-icon" src="@/assets/images/icon5.png" />
            <div class="card-item-name">复活卡X{{ ResurgentCard }}</div>
          </div>
        </div>
        <div class="wenben" @click="getAccept">开心收下</div>
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="fragmentShow = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { list1, list2, list3, list4, list5, list6 } from "@/utlis/listData.js";
export default {
  filters: {
    fromDate(data) {
      return data.slice(0, 16);
    }
  },
  data() {
    return {
      imgSrc:
        "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/photo1.png",
      imgSrc2: require("@/assets/images/xbzq/right-top.png"),
      imgSrc3: require("@/assets/images/xbzq/btn-bg.png"),
      type: null,
      list: [],
      percentage1: 0,
      percentage2: 0,
      allLevelList: [],
      newLevelList: [],
      coordinateList: [],
      luboIndex: 0,
      lightType: 1,
      page1: 1,
      jigsawPieceRecordsList: [],
      flag: false,
      fragmentShow: false,
      islingqu: false,
      Points: 0, //金币
      LotteryCard: 0, //抽奖
      DoubleCard: 0, //翻倍
      UncheckCard: 0, //免审
      ResurgentCard: 0, //复活

      isbtn: false
    };
  },
  async created() {
    await this.jigsawLevels();
    await this.memberJigsawLevels();
    await this.getJigsawPieceRecords();
    this.countfun();
  },
  computed: {
    isIoconNumber() {
      let number = 0;

      if (this.Points) {
        number++;
      }
      if (this.LotteryCard) {
        number++;
      }
      if (this.DoubleCard) {
        number++;
      }
      if (this.UncheckCard) {
        number++;
      }
      if (this.ResurgentCard) {
        number++;
      }

      return number;
    }
  },
  methods: {
    format1() {
      let number = null;
      if (this.coordinateList) {
        number = this.coordinateList.length + "/" + (this.type + 3);
      }
      return number;
    },
    format2() {
      let number = null;
      if (this.allLevelList.length !== 0) {
        number = this.type + "/" + this.allLevelList.length;
      }
      return number;
    },
    gourl() {
      this.$router.push("/tasks");
    },
    //全部关卡
    async jigsawLevels() {
      const data = await this.$http.get("/api/v1/jigsawLevels");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        this.allLevelList = response.response;
      }
    },
    //完成关卡
    async memberJigsawLevels() {
      const data = await this.$http.get("/api/v1/memberJigsawLevels");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        this.newLevelList = response.response;
        console.log("this.newLevelList", this.newLevelList);
      }
    },
    //计算相关信息
    async countfun() {
      //判断是第几关

      // 判断是否有奖励没领取
      const awardStatusIndex = this.newLevelList.findIndex(item => {
        return item.AwardStatus === null || item.AwardStatus === 1;
      });

      if (awardStatusIndex !== -1) {
        this.type = awardStatusIndex + 1;
      } else if (this.allLevelList.length === this.newLevelList.length) {
        this.type = this.newLevelList.length;
      } else {
        this.type = this.newLevelList.length + 1;
      }
      // this.type = 1;

      this.chagenIslingqu();
      this.imgSrc = `https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/photo${this.type}.png`;
      console.log("this.type", this.type);

      //获取关卡碎片
      switch (this.type) {
        case 1:
          this.list = list1;
          break;
        case 2:
          this.list = list2;
          break;
        case 3:
          this.list = list3;
          break;
        case 4:
          this.list = list4;
          break;
        case 5:
          this.list = list5;
          break;
        case 6:
          this.list = list6;
          break;
      }

      //计算关卡进度
      this.percentage2 = parseInt((this.type * 100) / this.allLevelList.length);

      //获取当前关卡碎片
      let newList = [];
      const data = await this.$http.get(
        `/api/v1/memberJigsawPieces?progressID=${this.type}`
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        response.response.forEach(item => {
          //过滤掉非当前关卡的碎片
          if (item.ProgressID === this.type) {
            newList.push(item.Coordinate);
          }
        });
      }
      this.coordinateList = Array.from(new Set(newList));
      console.log("111", this.coordinateList, this.allLevelList);
      //获取当前关卡所需全部碎片
      let allCoordinateNumber = this.type + 3;

      //计算碎片进度
      this.percentage1 = parseInt(
        (this.coordinateList.length * 100) / allCoordinateNumber
      );
      if (this.percentage1 > 100) {
        this.percentage1 = 100;
      }
      if (this.percentage2 > 100) {
        this.percentage2 = 100;
      }
      console.log(
        "111",
        " this.percentage1",
        this.percentage1,
        this.percentage2
      );
    },

    //判断奖励是否领取
    chagenIslingqu() {
      if (this.newLevelList[this.type - 1]) {
        if (this.newLevelList[this.type - 1].AwardStatus === 2) {
          this.islingqu = true;
        } else {
          this.islingqu = false;
        }
      }
    },
    //切换上一个
    prev() {
      if (this.luboIndex === 0) {
        this.$message.warning("已经到最左边了");
        return;
      }
      this.luboIndex--;
      const right = this.luboIndex * 180;
      const dom = document.getElementById("lubo");
      dom.style.transform = `translateX(-${right}px)`;
    },
    //切换下一个
    next() {
      if (this.luboIndex === this.type - 1) {
        this.$message.warning("已经到最右边了");
        return;
      }
      this.luboIndex++;
      const right = this.luboIndex * 180;
      const dom = document.getElementById("lubo");
      dom.style.transform = `translateX(-${right}px)`;
    },
    changeLightType(type) {
      this.lightType = type;
      if (type === 1) {
        this.page1 = 1;
        this.jigsawPieceRecordsList = [];
        this.getJigsawPieceRecords();
      } else {
        this.page1 = 1;
      }
    },
    //点亮记录历史
    async getJigsawPieceRecords() {
      this.flag = false;
      const data = await this.$http.get(
        `/api/v1/jigsawPieceRecords?page=${this.page1}&size=10`
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("this.jigsawPieceRecords", response.response.data);

        if (response.response.data.length !== 0) {
          this.flag = true;
          this.page1++;
          this.jigsawPieceRecordsList = this.jigsawPieceRecordsList.concat(
            response.response.data
          );
        } else {
          this.$message.warning("没有更多数据");
        }
      }
    },
    scroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollHeight - (scrollTop + clientHeight) <= 0) {
        if (this.flag) {
          this.getJigsawPieceRecords();
        }
      }
    },
    async getRewards() {
      if (this.isbtn) {
        return;
      }
      if (this.percentage1 === 100 && !this.islingqu) {
        console.log(this.newLevelList);
        this.isbtn = true;
        const data = this.newLevelList[this.type - 1];
        this.Points = data.Points;
        this.LotteryCard = data.LotteryCard;
        this.DoubleCard = data.DoubleCard;
        this.UncheckCard = data.UncheckCard;
        this.ResurgentCard = data.ResurgentCard;

        let id = this.newLevelList[this.type - 1].ID;
        const data2 = await this.$http.post(`/api/v1/pickupReward`, null, {
          params: {
            progressID: id
          }
        });

        let response = JSON.parse(data2.request.response);
        if (response.status === 200 && response.response) {
          this.fragmentShow = true;
          await this.memberJigsawLevels();
          this.countfun();
          this.isbtn = false;
        }
      }
    },
    //收下奖励
    async getAccept() {
      this.fragmentShow = false;
    }
  }
};
</script>
<style scoped>
.progress-box-chind >>> .el-progress-bar__outer {
  background: linear-gradient(163deg, #cd5c18 0%, #883605 100%);
}
</style>
<style lang="scss" scoped>
.mian {
  width: 100%;
  background-color: #ff563f;
  min-width: 768px;
  padding-bottom: 40px;

  .main-top {
    width: 100%;
    position: relative;

    .top-bar {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .main-top-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-10%);
      width: 460px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      text-align: center;
      color: #ff2c2c;
      letter-spacing: 2px;
      background-color: #ffeac3;
      z-index: 2;
    }
  }

  .main-bottom {
    height: 600px;
    width: 100%;
    position: relative;

    .main-content-fa {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 1004px;
      height: 552px;
      background-color: #ffba53;
      border-radius: 12px 12px 12px 12px;
      padding: 11px;
      box-sizing: border-box;

      .main-content {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fff0db;
        border-radius: 12px;

        .content-left {
          width: 472px;
          height: 478px;
          background: #ffa34f;
          box-shadow: 0px 4px 2px 0px #ee8421,
            inset 3px 5px 4px 0px rgba(255, 255, 255, 0.25);
          border-radius: 10px 10px 10px 10px;
          margin-left: 30px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          .img-box {
            width: 450px;
            height: 450px;
            // border: 1px solid #fff;
            border-radius: 9px;
            position: relative;
            background-size: 100% 100%;
            background-position: center;
          }
        }

        .content-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 388px;
          margin-left: 45px;

          .right-top-title {
            width: 288px;
            height: 72px;
            background-size: 100% 100%;
            background-position: center;
            color: #fff;
            font-size: 24px;
            font-weight: bolder;
            letter-spacing: 2px;
            text-align: center;
            line-height: 72px;
          }

          .text {
            font-size: 16px;
            color: #e15400;
            text-align: left;
            width: 100%;

            span {
              display: inline-block;
              width: 8px;
              height: 8px;
              transform: rotate(45deg);
              background-color: #ff792a;
              margin-right: 6px;
            }
          }

          .text1 {
            margin-top: 27px;
          }

          .text2 {
            margin-top: 7px;
          }

          .text3 {
            margin-top: 10px;
          }

          .progress-box {
            display: flex;
            width: 100%;
            margin-top: 30px;
            justify-content: space-between;

            .progress-left {
              display: flex;
              align-items: center;

              .icon {
                width: 30px;
                height: 30px;
              }

              .progress-box-chind {
                width: 130px;
                height: 30px;
                background: linear-gradient(163deg, #ffac42 0%, #ffc45b 100%);
                border-radius: 15px;
                padding: 7px;
                box-sizing: border-box;
                margin-left: 13px;
                position: relative;
              }
            }

            .progress-right {
              display: flex;
              align-items: center;

              .icon {
                width: 30px;
                height: 30px;
              }

              .progress-box-chind {
                width: 130px;
                height: 30px;
                background: linear-gradient(163deg, #ffac42 0%, #ffc45b 100%);
                border-radius: 15px;
                padding: 7px;
                box-sizing: border-box;
                margin-left: 13px;
                position: relative;
              }
            }
          }

          .btns {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
            width: 100%;

            .btns-box {
              cursor: pointer;
              width: 166px;
              height: 50px;
              background-size: 100% 100%;
              background-position: center;
              font-size: 18px;
              color: #fff;
              display: flex;
              justify-content: center;
              padding-top: 10px;

              // align-items: center;
              img {
                width: 26px;
                height: 26px;
                margin-right: 14px;
                margin-top: 6px;
              }
            }

            .btns-box2 {
              cursor: no-drop;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  // 碎片
  .fragment-box {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 1004px;

    .fragment-top {
      width: 100%;
    }

    .fragment-icon {
      position: absolute;
      width: 15px;
      z-index: 1;
      top: -13px;
    }

    .fragment-icon-left {
      left: 141px;
    }

    .fragment-icon-right {
      right: 141px;
    }

    .lubo-box-fa {
      width: 1004px;
      height: 277px;
      background-color: #ffba53;
      border-radius: 12px 12px 12px 12px;
      padding: 11px;
      position: relative;
      top: -32px;

      .lubo-box {
        width: 100%;
        height: 100%;
        background-color: #fff0db;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        .lubo {
          display: table;
          width: auto;
          height: 100%;
          transition: transform ease 0.3s;
          display: flex;
          padding: 0 97px;

          .lubo-item {
            display: table-cell;
            flex-shrink: 0;
            margin-right: 40px;
            width: 140px;

            .lubo-item-top-box {
              width: 140px;
              height: 140px;
              background: #ffb330;
              border-radius: 15px 15px 15px 15px;
              margin-top: 39px;
              display: flex;
              justify-content: center;
              align-items: center;

              .lubo-item-fragment {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .fragment2-img {
                  max-width: 100%;
                  max-height: 100%;
                }

                .triangle-dui {
                  width: 28px;
                  height: 28px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                }
              }
            }

            .lubo-item-bottom-box {
              width: 104px;
              height: 34px;
              background: #ffb330;
              border-radius: 17px;
              margin-top: 20px;
              margin-left: 18px;
              text-align: center;
              line-height: 34px;
              color: #fff;
            }

            .lubo-item-bottom-box-active {
              background: linear-gradient(357deg, #ff6332 0%, #ff8500 100%);
            }
          }
        }

        .lubo-btn {
          position: absolute;
          width: 80px;
          height: 100%;
          background: #fff0db;
          top: 0;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 32px;
            height: 32px;
          }
        }

        .prev-box {
          box-shadow: 9px 0px 5px 0px rgba(0, 0, 0, 0.08);
          left: 0;
        }

        .next-box {
          right: 0;
          box-shadow: -9px 0px 5px 0px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  .Light-tabar {
    position: relative;
    display: flex;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    width: 1004px;
    z-index: 1;

    .Light-box {
      width: 165px;
      height: 49px;
      line-height: 49px;
      background: #ffba53;
      border-radius: 8px 8px 0px 0px;
      color: #8f4016;
      font-size: 18px;
      text-align: center;
      letter-spacing: 2px;
      margin: 0 107px;
      cursor: pointer;
    }

    .Light-box-active {
      background-color: #fff0db;
    }
  }

  .Light-award {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 1004px;
    background-color: #ffba53;
    border-radius: 12px 12px 12px 12px;
    padding: 11px;

    .Light-award-list {
      width: 100%;
      height: 100%;
      background-color: #fff0db;
      border-radius: 12px;
      height: 179px;
      overflow: auto;
      position: relative;

      .Light-award-item {
        display: flex;
        justify-content: center;
        color: #202020;
        font-size: 14px;
        margin: 20px 0;

        .list-content {
          width: 100px;
          text-align: center;
          margin: 0 50px;
        }

        .list-AwardStatus {
          width: 100px;
          text-align: center;
          margin-left: 10px;
        }
      }

      .Light-award-box-tips {
        position: relative;
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
        text-align: center;
        color: #202020;
        font-size: 14px;
      }
    }
  }
}

.img-abs {
  position: absolute;
}

.img-abs-dis {
  display: none !important;
}

.img-abs0 {
  left: 0;
  top: 0;
  width: 249px;
  height: 249px;
}

.img-abs1 {
  right: 0;
  top: 0;
  width: 225px;
  height: 225px;
}

.img-abs2 {
  left: 0;
  bottom: 0;
  width: 225px;
  height: 225px;
}

.img-abs3 {
  right: 0;
  bottom: 0;
  width: 249px;
  height: 249px;
}

// 第二个
.img-abs4 {
  left: 0;
  top: 0;
  width: 243px;
  height: 168px;
}

.img-abs5 {
  right: 0;
  top: 0;
  width: 225px;
  height: 150px;
}

.img-abs6 {
  left: 0;
  top: 150px;
  width: 225px;
  height: 150px;
}

.img-abs7 {
  right: 0;
  top: 132px;
  width: 243px;
  height: 186px;
}

.img-abs8 {
  left: 0;
  bottom: 0;
  width: 450px;
  height: 168px;
}

// 第三
.img-abs9 {
  left: 0;
  top: 0;
  width: 243px;
  height: 168px;
}

.img-abs10 {
  right: 0;
  top: 0;
  width: 225px;
  height: 150px;
}

.img-abs11 {
  left: 0;
  top: 150px;
  width: 225px;
  height: 150px;
}

.img-abs12 {
  right: 0;
  top: 131px;
  width: 243px;
  height: 188px;
}

.img-abs13 {
  left: 0;
  bottom: 0;
  width: 243px;
  height: 168px;
}

.img-abs14 {
  right: 0;
  bottom: 0;
  width: 225px;
  height: 150px;
}

// 第四
.img-abs15 {
  left: 0;
  top: 0;
  width: 167px;
  height: 167px;
}

.img-abs16 {
  left: 150px;
  top: 0;
  width: 150px;
  height: 150px;
}

.img-abs17 {
  right: 0;
  top: 0;
  width: 167px;
  height: 167px;
}

.img-abs18 {
  left: 0;
  top: 150px;
  width: 150px;
  height: 150px;
}

.img-abs19 {
  left: 132px;
  top: 131px;
  width: 185px;
  height: 169px;
}

.img-abs20 {
  right: 0;
  top: 150px;
  width: 150px;
  height: 150px;
}

.img-abs21 {
  right: -0.75px;
  bottom: 0;
  width: 450px;
  height: 167px;
}

// 第五
.img-abs22 {
  left: 0;
  top: 0;
  width: 167px;
  height: 167px;
}

.img-abs23 {
  left: 150px;
  top: 0;
  width: 150px;
  height: 150px;
}

.img-abs24 {
  right: 0;
  top: 0;
  width: 167px;
  height: 167px;
}

.img-abs25 {
  left: 0;
  top: 150px;
  width: 150px;
  height: 167px;
}

.img-abs26 {
  left: 132px;
  top: 131px;
  width: 185px;
  height: 169px;
}

.img-abs27 {
  right: 0;
  top: 150px;
  width: 150px;
  height: 150px;
}

.img-abs28 {
  left: 0;
  bottom: 0;
  width: 242px;
  height: 150px;
}

.img-abs29 {
  right: 0;
  bottom: 0;
  width: 225px;
  height: 167px;
}

// 第六
.img-abs30 {
  left: 0;
  top: 0;
  width: 167px;
  height: 167px;
}

.img-abs31 {
  left: 150px;
  top: 0;
  width: 150px;
  height: 150px;
}

.img-abs32 {
  right: 0;
  top: 0;
  width: 167px;
  height: 167px;
}

.img-abs33 {
  left: 0;
  top: 150px;
  width: 150px;
  height: 167px;
}

.img-abs34 {
  left: 132px;
  top: 131px;
  width: 186px;
  height: 186px;
}

.img-abs35 {
  right: 0;
  top: 150px;
  width: 150px;
  height: 150px;
}

.img-abs36 {
  left: 0;
  bottom: 0;
  width: 167px;
  height: 150px;
}

.img-abs37 {
  left: 150px;
  bottom: 0;
  width: 150px;
  height: 150px;
}

.img-abs38 {
  right: 0;
  bottom: 0;
  width: 167px;
  height: 167px;
}
</style>
<style lang="scss" scoped>
.zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
}

.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 303px;
  height: 438px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .jlt-box {
    width: 303px;
    height: 438px;
    position: relative;
    animation: grow 1s;
    animation-fill-mode: forwards;
    /* 保留动画结束时的状态 */

    .jltc {
      width: 303px;
      height: 438px;
    }

    .lv_jltc {
      // width: 100px;
      height: 118px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-40%) translateX(-50%);
    }

    .jltc-name {
      position: absolute;
      top: 160px;
      color: #812704;
      text-align: center;
      font-size: 20px;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .jltc-card {
      position: absolute;
      top: 200px;
      color: #812704;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .card-item {
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #ffffff;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .card-icon {
          position: absolute;
          display: block;
          left: 50%;
          transform: translateX(-50%);
        }

        .card-item-name {
          text-align: center;
        }
      }
    }

    .jltc-card2 {
      justify-content: center;
      top: 240px;

      .card-item {
        width: 89px;
        height: 88px;
        border-radius: 8px;
        margin-right: 38px;

        .card-icon {
          width: 58px;
          height: auto;
          top: -16px;
        }

        .card-icon1 {
          top: -28px;
        }

        .card-item-name {
          margin-bottom: 25px;
          color: #812704;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .card-item:last-child {
        margin-right: 0;
      }
    }

    .jltc-card3 {
      justify-content: center;
      top: 240px;

      .card-item {
        width: 73px;
        height: 72px;
        border-radius: 8px;
        margin-right: 14px;

        .card-icon {
          width: 50px;
          height: auto;
          top: -12px;
        }

        .card-icon1 {
          top: -25px;
        }

        .card-item-name {
          margin-bottom: 19px;
          color: #812704;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .card-item:last-child {
        margin-right: 0;
      }
    }

    .jltc-card4 {
      justify-content: center;
      top: 200px;

      .card-item {
        width: 73px;
        height: 55px;
        border-radius: 6px;
        margin-right: 75px;
        margin-top: 23px;

        .card-icon {
          width: 50px;
          height: auto;
          top: -12px;
        }

        .card-icon1 {
          top: -25px;
        }

        .card-item-name {
          margin-bottom: 12px;
          color: #812704;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .card-item:nth-child(2n) {
        margin-right: 0;
      }
    }

    .jltc-card5 {
      justify-content: space-around;
      top: 200px;
      width: calc(100% - 40px);
      margin-left: 20px;

      .card-item {
        width: 73px;
        height: 55px;
        border-radius: 6px;
        margin-right: 13px;
        margin-top: 23px;

        .card-icon {
          width: 50px;
          height: auto;
          top: -12px;
        }

        .card-icon1 {
          top: -25px;
        }

        .card-item-name {
          margin-bottom: 12px;
          color: #812704;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .card-item:nth-child(3n) {
        margin-right: 0;
      }
    }

    .wenben {
      width: 150px;
      height: 43px;
      background: linear-gradient(180deg, #ff5330 0%, #ffb127 100%);
      border-radius: 21.5px;
      color: #fff;
      text-align: center;
      line-height: 43px;
      position: absolute;
      bottom: 27px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      font-size: 16px;
    }
  }

  .close {
    position: absolute;
    right: -48px;
    top: 48px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
